.login {
  background: linear-gradient(-30deg, #12bbad  0%, #237fc3 100%);

  color: #fff;

  #nav a {
    color: #fff;
  }

  #nav a:hover {
    color: #000 !important;
  }


  #backtoblog a {
    color: #000;
  }

  #backtoblog a:hover {
    color: #fff;
  }

  h1 a {
    background-image: url('../images/valelogo_login.jpg');
    background-size: 320px 166px;
    width: 320px;
    height: 166px;
    margin-bottom: 0;
  }

  #loginform {
    margin-top: 0;
    #user_login {
      border-color: #237fc3;
    }
    #user_pass {
      border-color: #237fc3;
    }
  }

  #login_error {
    border-left-color: #dc3232;
    color: #000;
  }

  .message {
    color: #000;
  }


}